import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { autoRefillActions } from 'state/auto-refill/auto-refill.reducer';
import { easyRefillActions } from 'state/easy-refill/easy-refill.reducer';
import { familyAccountActions } from 'state/family-account/family-account.reducer';
import { joinMembershipPlanActions } from 'state/join-membership-plan/join-membership-plan.reducer';

export type flowTypes = 'easy-refill' | ' auto-refill' | 'caregiver-access' | 'join-membership-plan';

const useResetBearerToken = (
    actionsFlow: Record<string, ActionCreatorWithoutPayload<string>>,
    flowParameter: string | null
) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (flowParameter && actionsFlow[flowParameter]) {
            dispatch(actionsFlow[flowParameter]());
        }
    }, [actionsFlow, dispatch, flowParameter]);
};

/**
 * @param flowParameter Get the flow where the user is comming to reset the specific token.
 */
const useResetToken = (flowParameter: flowTypes) => {
    const actionsFlow = {
        'easy-refill': easyRefillActions.resetBearerToken,
        'auto-refill': autoRefillActions.resetBearerToken,
        'caregiver-access': familyAccountActions.resetBearerToken,
        'join-membership-plan': joinMembershipPlanActions.resetBearerToken
    };

    return useResetBearerToken(actionsFlow, flowParameter);
};

export default useResetToken;
